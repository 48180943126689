<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="상담 내용" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn v-if="isOld && editable" label="LBLREMOVE" icon="remove" @btnClicked="removeConsult" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="conData"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveConsult"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-field
                  required
                  type="detail_user"
                  :editable="editable"
                  :isSuspect="true"
                  :data="conData"
                  deptValue="deptCd"
                  label="팀/반/사번/성함/생년월일"
                  name="userId"
                  v-model="conData.userId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  required
                  :editable="editable"
                  codeGroupCd="SUSPECT_CONSULT_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="consultTypeCd"
                  label="상담구분"
                  v-model="conData.consultTypeCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  label="상담일"
                  name="consultDate"
                  default="today"
                  v-model="conData.consultDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  label="상담자"
                  :editable="editable"
                  name="counselorName"
                  v-model="conData.counselorName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="흡연"
                  name="smoking"
                  v-model="conData.smoking">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="음주"
                  name="drinking"
                  v-model="conData.drinking">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="운동"
                  name="exercise"
                  v-model="conData.exercise">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="가족력"
                  name="consultContent"
                  v-model="conData.consultContent">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  label="소견"
                  :editable="editable"
                  name="symptom"
                  :rows="2"
                  v-model="conData.symptom">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  label="현병력/복용 약물"
                  :editable="editable"
                  name="diseasePast"
                  :rows="2"
                  v-model="conData.diseasePast">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  required
                  label="상담내용"
                  :editable="editable"
                  :rows="2"
                  name="diseaseCurrent"
                  v-model="conData.diseaseCurrent">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  label="기타 특이사항"
                  :editable="editable"
                  :rows="2"
                  name="remark"
                  v-model="conData.remark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="간이검사결과" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="이완기"
                  name="diastole"
                  v-model="conData.diastole">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="수축기"
                  name="systole"
                  v-model="conData.systole">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="혈당"
                  name="bloodSugar"
                  v-model="conData.bloodSugar">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="당화혈색소"
                  name="glycatedHemoglobin"
                  v-model="conData.glycatedHemoglobin">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="간수치(OT)"
                  name="liverOt"
                  v-model="conData.liverOt">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="간수치(PT)"
                  name="liverPt"
                  v-model="conData.liverPt">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="악력(좌)"
                  name="gripStrengthLeft"
                  v-model="conData.gripStrengthLeft">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="악력(우)"
                  name="gripStrengthRight"
                  v-model="conData.gripStrengthRight">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="소변검사결과"
                  name="peeCheckResult"
                  v-model="conData.peeCheckResult">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="이상지질혈증(총콜레스테롤)"
                  name="totalCholesterol"
                  v-model="conData.totalCholesterol">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="이상지질혈증(LDL콜레스테롤)"
                  name="ldlCholesterol"
                  v-model="conData.ldlCholesterol">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="이상지질혈증(HDL콜레스테롤)"
                  name="hdlCholesterol"
                  v-model="conData.hdlCholesterol">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="중성지방"
                  name="neutralFat"
                  v-model="conData.neutralFat">
                </c-text>
              </div>
            </template>
          </c-card>
          <div class="col-12" style="padding-top:25px!important">
            <c-upload
              :attachInfo="attachInfo"
              :editable="editable"
              label="관련 파일">
            </c-upload>
          </div>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'consult-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        suspectConsultId: '',
        heaBrainCardiovascularSurveyId: '',
        suspectId: '',
        userId: '',
      }),
    },
  },
  data() {
    return {
      editable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HEA_USER_CONSULT',
        taskKey: '',
      },
      conData: {
        suspectConsultId: '',
        suspectId: '',
        heaBrainCardiovascularSurveyId: '',
        suspectUserId: '',
        consultContent: '',
        consultDate: '',
        diseasePast: '',
        diseaseCurrent: '',
        symptom: '',
        remark: '',
        consultTypeCd: null,
        counselorName: '',
        glycatedHemoglobin: '',
        liverOt: '',
        liverPt: '',
        gripStrengthLeft: '',
        gripStrengthRight: '',
        peeCheckResult: '',
        ldlCholesterol: '',
        hdlCholesterol: '',
        neutralFat: '',
        smoking: '',
        drinking: '',
        exercise: '',
        bloodSugar: '',
        totalCholesterol: '',
        diastole: '',
        systole: '',
        regUserId: '',
        chgUserId: '',
        deptCd: '',
        userId: '',
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'medicineName',
            field: 'medicineName',
            label: '약품명',
            style: 'width:170px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'medicineRecentCount',
            field: 'medicineRecentCount',
            label: '현재 재고량',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'medicineCount',
            field: 'medicineCount',
            label: '투약수량',
            style: 'width:150px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: 'LBLUNIT',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'medicinePeriod',
            field: 'medicinePeriod',
            label: '투약기간',
            style: 'width:200px',
            align: 'left',
            range: true,
            type: 'date',
            sortable: false,
          },
          {
            name: 'howToUse',
            field: 'howToUse',
            label: '효능/투약방법',
            style: 'width:150px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLREMARK',
            style: 'width:250px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      getUrl: '',
      saveUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      isSave: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.suspectConsultId)
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.hea.checkup.suspect.consult.get.url;
      this.saveUrl = transactionConfig.hea.checkup.suspect.consult.insert.url
      this.insertUrl = transactionConfig.hea.checkup.suspect.consult.insert.url;
      this.updateUrl = transactionConfig.hea.checkup.suspect.consult.update.url;
      this.deleteUrl = transactionConfig.hea.checkup.suspect.consult.delete.url;

      if (this.popupParam.suspectId) {
        this.$set(this.conData, 'suspectId', this.popupParam.suspectId);
        this.$set(this.conData, 'consultTypeCd', 'SCT0000002');
        this.$set(this.conData, 'userId', this.popupParam.userId);
      }
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.suspectConsultId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.suspectConsultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.conData = this.$_.clone(_result.data);
          this.$set(this.attachInfo, 'taskKey', this.popupParam.suspectConsultId);
        },);
      } 
    },
    saveConsult() {
      if (this.popupParam.suspectConsultId) {
        this.mappingType = 'PUT';
        this.saveUrl = this.updateUrl;
      } else {
        this.mappingType = 'POST';
        this.saveUrl = this.insertUrl;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error 
            // 확인 callback 함수
            confirmCallback: () => {
              this.conData.regUserId = this.$store.getters.userId;
              this.conData.chgUserId = this.$store.getters.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.suspectConsultId = result.data
      this.$set(this.attachInfo, 'taskKey',  this.popupParam.suspectConsultId)
      this.$set(this.attachInfo, 'isSubmit', this.popupParam.suspectConsultId)
      this.getDetail();
    },
    removeConsult() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.suspectConsultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          })
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>